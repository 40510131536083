import React, { Component } from 'react'

import logo from "./images/logo.png"
import discord from "./images/discord.svg"
import medium from "./images/medium.svg"
import opensea from "./images/opensea.svg"
import twitter from "./images/twitter.svg"
import image1 from "./images/image1.png"
import img1 from "./images/1.png"
import img2 from "./images/2.png"
import img3 from "./images/3.png"
import img4 from "./images/4.png"
import img5 from "./images/5.png"
import img6 from "./images/6.png"
import img7 from "./images/7.png"
import img8 from "./images/8.png"
import img9 from "./images/9.png"
import img10 from "./images/10.png"
import rider from "./images/rider.png"
import chevronDown from "./images/chevron-down.svg"
import jQuery from 'jquery'
import styles from "./index.css";
import BackToUp from '@uiw/react-back-to-top';
import PageScrollProgressBar from "react-page-scroll-progress-bar";

import { Accordion, AccordionItem as Item } from '@szhsin/react-accordion';


export default class App extends Component {


  jQuerycode = () => {
    jQuery(document).ready(function ($) {
      //set animation timing
      var animationDelay = 2500,
        //loading bar effect
        barAnimationDelay = 3800,
        barWaiting = barAnimationDelay - 3000, //3000 is the duration of the transition on the loading bar - set in the scss/css file
        //letters effect
        lettersDelay = 50,
        //type effect
        typeLettersDelay = 150,
        selectionDuration = 500,
        typeAnimationDelay = selectionDuration + 800,
        //clip effect 
        revealDuration = 600,
        revealAnimationDelay = 1500;

      initHeadline();


      function initHeadline() {
        //insert <i> element for each letter of a changing word
        singleLetters($('.cd-headline.letters').find('b'));
        //initialise headline animation
        animateHeadline($('.cd-headline'));
      }

      function singleLetters($words) {
        $words.each(function () {
          var word = $(this),
            letters = word.text().split('');

          var newLetters = letters.join('');
          word.html(newLetters).css('opacity', 1);
        });
      }

      function animateHeadline($headlines) {
        var duration = animationDelay;
        $headlines.each(function () {
          var headline = $(this);

          if (headline.hasClass('loading-bar')) {
            duration = barAnimationDelay;
            setTimeout(function () { headline.find('.cd-words-wrapper').addClass('is-loading') }, barWaiting);
          } else if (headline.hasClass('clip')) {
            var spanWrapper = headline.find('.cd-words-wrapper'),
              newWidth = spanWrapper.width() + 10
            spanWrapper.css('width', newWidth);
          } else if (!headline.hasClass('type')) {
            //assign to .cd-words-wrapper the width of its longest word
            var words = headline.find('.cd-words-wrapper b'),
              width = 0;
            words.each(function () {
              var wordWidth = $(this).width();
              if (wordWidth > width) width = wordWidth;
            });
            headline.find('.cd-words-wrapper').css('width', width);
          };

          //trigger animation
          setTimeout(function () { hideWord(headline.find('.is-visible').eq(0)) }, duration);
        });
      }

      function hideWord($word) {
        var nextWord = takeNext($word);

        if ($word.parents('.cd-headline').hasClass('type')) {
          var parentSpan = $word.parent('.cd-words-wrapper');
          parentSpan.addClass('selected').removeClass('waiting');
          setTimeout(function () {
            parentSpan.removeClass('selected');
            $word.removeClass('is-visible').addClass('is-hidden').children('i').removeClass('in').addClass('out');
          }, selectionDuration);
          setTimeout(function () { showWord(nextWord, typeLettersDelay) }, typeAnimationDelay);

        } else if ($word.parents('.cd-headline').hasClass('letters')) {
          var bool = ($word.children('i').length >= nextWord.children('i').length) ? true : false;
          hideLetter($word.find('i').eq(0), $word, bool, lettersDelay);
          showLetter(nextWord.find('i').eq(0), nextWord, bool, lettersDelay);

        } else if ($word.parents('.cd-headline').hasClass('clip')) {
          $word.parents('.cd-words-wrapper').animate({ width: '2px' }, revealDuration, function () {
            switchWord($word, nextWord);
            showWord(nextWord);
          });

        } else if ($word.parents('.cd-headline').hasClass('loading-bar')) {
          $word.parents('.cd-words-wrapper').removeClass('is-loading');
          switchWord($word, nextWord);
          setTimeout(function () { hideWord(nextWord) }, barAnimationDelay);
          setTimeout(function () { $word.parents('.cd-words-wrapper').addClass('is-loading') }, barWaiting);

        } else {
          switchWord($word, nextWord);
          setTimeout(function () { hideWord(nextWord) }, animationDelay);
        }
      }

      function showWord($word, $duration) {
        if ($word.parents('.cd-headline').hasClass('type')) {
          showLetter($word.find('i').eq(0), $word, false, $duration);
          $word.addClass('is-visible').removeClass('is-hidden');

        } else if ($word.parents('.cd-headline').hasClass('clip')) {
          $word.parents('.cd-words-wrapper').animate({ 'width': $word.width() + 10 }, revealDuration, function () {
            setTimeout(function () { hideWord($word) }, revealAnimationDelay);
          });
        }
      }

      function hideLetter($letter, $word, $bool, $duration) {
        $letter.removeClass('in').addClass('out');

        if (!$letter.is(':last-child')) {
          setTimeout(function () { hideLetter($letter.next(), $word, $bool, $duration); }, $duration);
        } else if ($bool) {
          setTimeout(function () { hideWord(takeNext($word)) }, animationDelay);
        }

        if ($letter.is(':last-child') && $('html').hasClass('no-csstransitions')) {
          var nextWord = takeNext($word);
          switchWord($word, nextWord);
        }
      }

      function showLetter($letter, $word, $bool, $duration) {
        $letter.addClass('in').removeClass('out');

        if (!$letter.is(':last-child')) {
          setTimeout(function () { showLetter($letter.next(), $word, $bool, $duration); }, $duration);
        } else {
          if ($word.parents('.cd-headline').hasClass('type')) { setTimeout(function () { $word.parents('.cd-words-wrapper').addClass('waiting'); }, 200); }
          if (!$bool) { setTimeout(function () { hideWord($word) }, animationDelay) }
        }
      }

      function takeNext($word) {
        return (!$word.is(':last-child')) ? $word.next() : $word.parent().children().eq(0);
      }



      function switchWord($oldWord, $newWord) {
        $oldWord.removeClass('is-visible').addClass('is-hidden');
        $newWord.removeClass('is-hidden').addClass('is-visible');
      }
    });
    // open
    const burger = document.querySelectorAll('.navbar-burger');
    const menu = document.querySelectorAll('.navbar-menu');

    if (burger.length && menu.length) {
      for (var i = 0; i < burger.length; i++) {
        burger[i].addEventListener('click', function () {
          console.log('clicked')
          for (var j = 0; j < menu.length; j++) {
            menu[j].classList.toggle('hidden');
          }
        });
      }
    }

    // close
    const close = document.querySelectorAll('.navbar-close');
    const backdrop = document.querySelectorAll('.navbar-backdrop');

    if (close.length) {
      for (var i = 0; i < close.length; i++) {
        close[i].addEventListener('click', function () {
          for (var j = 0; j < menu.length; j++) {
            menu[j].classList.toggle('hidden');
          }
        });
      }
    }

    if (backdrop.length) {
      for (var i = 0; i < backdrop.length; i++) {
        backdrop[i].addEventListener('click', function () {
          for (var j = 0; j < menu.length; j++) {
            menu[j].classList.toggle('hidden');
          }
        });
      }
    }
  }

  componentDidMount() {
    this.jQuerycode()
  }

  render() {
    const AccordionItem = ({ header, ...rest }) => (
      <Item
        {...rest}
        header={
          <>
            {header}
            <img className="chevron" src={chevronDown} alt="Chevron Down" />
          </>
        }
        className="item"
        buttonProps={{
          className: ({ isEnter }) =>
            `${styles.itemBtn} ${isEnter && "itemBtnExpanded"}`
        }}
      />
    );
    return (

      <main className="overflow-y-hidden">
        <PageScrollProgressBar color="#4F5EC8" bgColor="#f2f2f2" height="2px" />

        <section>
          <nav className="flex justify-between p-6 px-4">
            <div className="flex items-center w-full lg:justify-center">
              <div className="hidden xl:flex">
                <ul className="flex w-full justify-center">
                  <li className="mr-12"><a className="text-black hover:text-gray-500 font-bold text-xl transition duration-150" href="#about">About</a></li>
                  <li className="mr-12"><a className="text-black hover:text-gray-500 font-bold text-xl transition duration-150" href="#roadmap">Roadmap</a></li>
                </ul>
              </div>
              <div className="">
                <a className="flex xl:justify-center max-w-max mx-auto" href="#hero">
                  <img className="h-28 mr-12 mx-auto" src={logo} alt="Fridoos" />
                </a>
              </div>
              <div className="hidden xl:flex">
                <ul className="flex w-full">

                  <li className="mr-12"><a className="text-black hover:text-gray-500 font-bold text-xl transition duration-150" href="#team">Team</a></li>
                  <li><a className="text-black hover:text-gray-500 font-bold text-xl transition duration-150" href="#faq">FAQ</a></li>
                </ul>
                
              </div>
              <div className="hidden lg:flex absolute right-10 items-center">
                <a href="https://twitter.com/fridoosNFT" target="_blank" rel="noreferrer"><img src={twitter} alt="Fridoos's twitter" className="h-10 mr-2" /></a>
                <a href="https://medium.com/token-times/fridoos-7cc75d1a786b" target="_blank" rel="noreferrer"><img src={medium} alt="Fridoos's medium" className="h-10 mr-2" /></a>
                <a href="https://discord.gg/4BYWftvs" target="_blank" rel="noreferrer"><img src={discord} alt="Fridoos's discord" className="h-10 mr-2" /></a>
                <a href="https://opensea.io/collection/fridoos" target="_blank" rel="noreferrer"><img src={opensea} alt="Fridoos's opensea" className="h-10 mr-2" /></a>
              </div>
            </div>

            <div className="lg:hidden ml-auto my-auto  ">
              <button className="navbar-burger flex items-center p-3 hover:bg-custom-gray text-gray-900 rounded">
                <svg className="w-10 h-3" width="39" height="13" viewBox="0 0 39 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="39" height="2" rx="1" fill="currentColor"></rect><rect x="19" y="11" width="20" height="2" rx="1" fill="currentColor"></rect>
                  <title>Mobile menu</title>
                </svg>
              </button>
            </div>
          </nav>
          <div className="navbar-menu fixed top-0 left-0 bottom-0 w-5/6 max-w-sm z-50 hidden">
            <div className="navbar-backdrop fixed inset-0 bg-purple-light opacity-80"></div>
            <nav className="relative flex flex-col py-8 w-full h-full bg-purple-light overflow-y-auto text-black">
              <div className="flex items-center justify-between mb-16 pr-6 px-10">
                <a className=" text-2xl text-white font-bold" href="#hero">
                  <img className="h-20" src={logo} alt="Fridoos" width="auto" />
                </a>
                <button class="navbar-close">
                <svg class="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
              </div>
              <div>
                <ul>
                  <li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-black font-bold hover:bg-gray-500 rounded-xl" href="#about">About</a></li>
                  <li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-black font-bold hover:bg-gray-500 rounded-xl" href="#roadmap">Roadmap</a></li>
                  <li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-black font-bold hover:bg-gray-500 rounded-xl" href="#team">Team</a></li>
                  <li className="mb-1 px-10"><a className="block pl-8 py-4 text-xl text-black font-bold hover:bg-gray-500 rounded-xl" href="#faq">FAQ</a></li>
                </ul>
                <div className="flex absolute right-10 items-center">
                <a href="https://twitter.com/fridoosNFT" target="_blank" rel="noreferrer"><img src={twitter} alt="Fridoos's twitter" className="h-10 mr-2" /></a>
                <a href="https://medium.com/token-times/fridoos-7cc75d1a786b" target="_blank" rel="noreferrer"><img src={medium} alt="Fridoos's medium" className="h-10 mr-2" /></a>
                <a href="https://discord.gg/V3dpsfvJKN" target="_blank" rel="noreferrer"><img src={discord} alt="Fridoos's discord" className="h-10 mr-2" /></a>
                <a href="https://opensea.io/collection/fridoos" target="_blank" rel="noreferrer"><img src={opensea} alt="Fridoos's opensea" className="h-10 mr-2" /></a>
              </div>
              </div>
              
            </nav>
          </div>
        </section>
        <section className="container mx-auto mb-64" id="hero">
          <div className="lg:flex mx-auto mb-8 justify-center items-center mt-24 min-h-full">
            <div className="w-1/3">
              <img src={image1} alt="NFT" className='lg:flex hidden'/>
            </div>
            <div className="lg:w-2/4 text-center lg:text-left">
              <h1 className="text-4xl font-bold">Hello we are the Fridoos.</h1>
              <section className="cd-intro mb-14">
                <h3 className="cd-headline push text-3xl ">
                  <span>A community </span>
                  <span className="cd-words-wrapper font-bold text-center">
                    <b className="is-visible">Project.</b>
                    <b>Driven.</b>
                  </span>
                </h3>


              </section>
              <a href="https://fridoos.com/coloring.pdf" className="mt-10 px-2 py-2 lg:px-8 lg:py-4 rounded-lg text-black bg-purple-light border-2 border-blue-dark font-bold lg:text-2xl hover:border-blue-500 transition duration-300 hover:bg-purple-light2">Get Your Coloring book</a>

            </div>

          </div>
        </section>
        
        <section>
          <div className="p-8">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 lg:gap-8">
              <div className="rounded-md flex items-center justify-center"><img src={img1} alt="nft" /></div>
              <div className="rounded-md flex items-center justify-center"><img src={img2} alt="nft" /></div>
              <div className="rounded-md flex items-center justify-center"><img src={img3} alt="nft" /></div>
              <div className="rounded-md flex items-center justify-center"><img src={img4} alt="nft" /></div>
              <div className="rounded-md flex items-center justify-center"><img src={img5} alt="nft" /></div>
              <div className="rounded-md flex items-center justify-center"><img src={img6} alt="nft" /></div>
              <div className="rounded-md flex items-center justify-center"><img src={img7} alt="nft" /></div>
              <div className="rounded-md flex items-center justify-center"><img src={img8} alt="nft" /></div>
              <div className="rounded-md flex items-center justify-center"><img src={img9} alt="nft" /></div>
              <div className="rounded-md flex items-center justify-center"><img src={img10} alt="nft" /></div>
            </div>
          </div>
        </section>



        <section className="container mx-auto mt-64" id="about">
          <h1 className="text-4xl pt-4 font-bold text-center">About us </h1>

          <div className="lg:flex justify-center">
            <div className="lg:w-1/2 p-16 lg:border-purple-light lg:border-r-4 mt-20">
              <h1 className="text-3xl text-center font-bold"> WHAT IS FRIDOOS</h1>

              <p className="text-3xl mt-20  text-center">
                FRIDOOS is a collection of 10 000 unique NFTS. Each FRIDOOS has their unique Vibe and power to match with your personality. </p>
            </div>
            <hr className="lg:hidden block mx-auto border-t-4 rounded-full border-t-purple-light" width="75%"  />

            <div className="lg:w-1/2 p-16">
              <h1 className="text-3xl mt-20 text-center font-bold"> FRIDOOS SPOTLIGHT</h1>

              <p className="text-3xl mt-20 text-center">
                FRIDOOS are unique piece of art.
                The concept for the fridoos artwork was truly a family affaire, the original drawing were created and developed by <span className='font-bold'> RID3R.ETH </span> and his family. </p>

            </div>
          </div>
          <section className="font-medium text-black mb-64 mt-5">
            <div className="container px-4 mx-auto">
              <div className="flex flex-wrap mx-auto max-w-5xl text-center">
                <div className="px-3 mb-5 lg:mb-0 w-full md:w-1/2 lg:w-1/4">
                  <div className="py-24 bg-purple-light rounded-3xl">
                    <h2 className="mb-3 font-bold text-4xl">Free mint</h2>
                    <h3 className="font-heading text-lg leading-6">Mint Price</h3>
                  </div>
                </div>
                <div className="px-3 mb-5 lg:mb-0 w-full md:w-1/2 lg:w-1/4">
                  <div className="lg:relative lg:top-14 py-24 bg-purple-light rounded-3xl">
                    <h2 className="mb-3 font-bold text-4xl">22 February</h2>
                    <h3 className="font-heading text-lg leading-6">Mint Date</h3>
                  </div>
                </div>
                <div className="px-3 mb-5 md:mb-0 lg:mb-0 w-full md:w-1/2 lg:w-1/4">
                  <div className="lg:relative lg:top-14 py-24 bg-purple-light rounded-3xl">
                    <h2 className="mb-3 font-bold text-4xl">10 000</h2>
                    <h3 className="font-heading text-lg leading-6">Total supply</h3>
                  </div>
                </div>
                <div className="px-3 w-full md:w-1/2 lg:w-1/4">
                  <div className="py-24 bg-purple-light rounded-3xl">
                    <h2 className="mb-3 font-bold text-4xl">Etherum</h2>
                    <h3 className="font-heading text-lg leading-6">Network</h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <section className="container mx-auto mt-64 mb-64" id="roadmap">
          <h1 className="text-4xl pt-4 font-bold text-center">Roadmap </h1>
          <div className="container px-4 mx-auto">
            <div className="p-6  rounded-xl">

              <div className="flex flex-wrap -m-2.5">
                <div className="w-full sm:w-1/2 p-2.5">
                  <div className="p-6 bg-purple-light hover:bg-light border rounded-lg">
                    <div className="flex flex-wrap -m-2">
                      <div className="flex-1 p-2">
                        <h3 className="text-xl font-bold">I. Royalties (50%) straight back to holders.
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-1/2 p-2.5">
                  <div className="p-6 bg-purple-light hover:bg-light border rounded-lg min-h-full">
                    <div className="flex flex-wrap -m-2">
                      <div className="flex-1 p-2">
                        <h3 className="text-xl font-bold">II. Original art.
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full sm:w-1/2 p-2.5">
                  <div className="p-6 bg-purple-light hover:bg-light border rounded-lg min-h-full">
                    <div className="flex flex-wrap -m-2">
                      <div className="flex-1 p-2">
                        <h3 className="text-xl font-bold">III. Full ip rights on the art
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              
                <div className="w-full p-2.5 sm:w-1/2">
                  <div className="p-6 bg-purple-light hover:bg-light border rounded-lg min-h-full">
                    <div className="flex flex-wrap -m-2">
                      <div className="flex-1 p-2">
                        <h3 className="text-xl font-bold">IV. Next Phases (Roadmap V2) Decided by the community (DAO Votes)</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full p-2.5">
                  <div className="p-6 bg-purple-light hover:bg-light border rounded-lg min-h-full">
                    <div className="flex flex-wrap -m-2">
                      <div className="flex-1 p-2 text-center">
                        <h3 className="text-xl font-bold">V. 20X 1/1 Full body and some special traits for future use... TBA
                          Can you find them?</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    
        <section className="container mx-auto mt-64 mb-64" id="team">
          <div className="p-8">
            <h1 className="text-4xl px-4 font-bold text-center">Team </h1>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-8 mt-5">
              <div className="rounded-full flex flex-col items-center justify-center"><img src={rider} alt="R1D3R.ETH" className="rounded-full h-56" /><h1 className="mt-4 font-bold text-xl">R1D3R.ETH</h1><h3 className="mt-2 text-lg">Artist / Founder</h3></div>
              <div className="rounded-md flex flex-col items-center justify-center"><img src={img2} alt="Kloaka.eth" className="rounded-full h-56" /><h1 className="mt-4 font-bold text-xl">Kloaka.eth</h1><h3 className="mt-2 text-lg">Advisor</h3></div>
              <div className="rounded-md flex flex-col items-center justify-center"><img src={img3} alt="pureNFTgems" className="rounded-full h-56" /><h1 className="mt-4 font-bold text-xl">pureNFTgems</h1><h3 className="mt-2 text-lg">Advisor</h3></div>

            </div>
          </div>
        </section>
        <section className="mt-64 container mx-auto" id="faq">
          <div className="container px-4 mx-auto">
            <div className="mb-20">
              <h2 className="mt-8 text-4xl font-bold text-center">FAQ&apos;s</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 lg:gap-8 mt-5">
              <Accordion transition transitionTimeout={200}>
                <div>
                  <AccordionItem className="mb-4 px-4 lg:px-12 lg:py-8 bg-purple-light rounded-2xl" initialEntered header={
                    <div>
                      <button className="flex w-full text-left ">
                        <div className="w-auto mr-8">
                          <span className="flex items-center justify-center w-12 h-12 text-lg font-bold bg-blue-100 rounded-full">1</span>
                        </div>
                        <div className="w-full mt-3">
                          <div className="flex items-center justify-between">
                            <h3 className="text-xl font-bold">What is FRIDOOS nft contract adress</h3>

                          </div>
                        </div>
                      </button>
                    </div>
                  }>
                    <button className="lg:flex w-full text-left">

                      <div className="mt-6 lg:border-l-2 lg:border-gray-50 lg:pl-10">
                        <p className="mb-5 lg:text-xl truncate">0x8A43094a807f5eE87D0c2809B1BEF32625787F9A</p>
                      </div>
                    </button>
                  </AccordionItem>
                </div>
                <div>
                  <AccordionItem className="mb-4 px-4 lg:px-12 py-8 bg-purple-light rounded-2xl" header={
                    <div>
                      <button className="flex w-full text-left ">

                        <div className="w-auto mr-8">
                          <span className="flex items-center justify-center w-12 h-12 text-lg font-bold bg-blue-100 rounded-full">2</span>
                        </div>
                        <div className="w-full mt-3">
                          <div className="flex items-center justify-between">
                            <h3 className="text-xl font-bold">What is the number of your FRIDOOS NFT supplies ?</h3>

                          </div>

                        </div>
                      </button>

                    </div>
                  }>
                    <button className="flex w-full text-left" >

                      <div className="mt-6 border-l-2 border-gray-50 pl-10">
                        <p className="text-xl">FRIDOOS is a collection of 10 000 uniques NFT.</p>
                      </div>
                    </button>
                  </AccordionItem>
                </div>
              </Accordion>
              <Accordion transition transitionTimeout={200}>
                <div>
                  <AccordionItem className="mb-4 px-4 lg:px-12 py-8 bg-purple-light rounded-2xl" header={
                    <div>
                      <button className="flex w-full text-left ">

                        <div className="w-auto mr-8">
                          <span className="flex items-center justify-center w-12 h-12 text-lg font-bold bg-blue-100 rounded-full">3</span>
                        </div>
                        <div className="w-full mt-3">
                          <div className="flex items-center justify-between">
                            <h3 className="text-xl font-bold">What is the cost of minting ?</h3>

                          </div>

                        </div>
                      </button>

                    </div>
                  }>
                    <button className="flex w-full text-left">


                      <div className=" mt-6 border-l-2 border-gray-50 pl-10">
                        <p className="text-xl">FRIDOOS is a FREE MINT</p>
                      </div>
                    </button>
                  </AccordionItem>
                </div>
                <div>
                  <AccordionItem className="mb-4 px-4 lg:px-12 py-8 bg-purple-light rounded-2xl" header={
                    <div>
                      <button className="flex w-full text-left ">

                        <div className="w-auto mr-8">
                          <span className="flex items-center justify-center w-12 h-12 text-lg font-bold bg-blue-100 rounded-full">4</span>
                        </div>
                        <div className="w-full mt-3">
                          <div className="flex items-center justify-between">
                            <h3 className="text-xl font-bold">What should i do if i need support?</h3>

                          </div>

                        </div>
                      </button>

                    </div>
                  }>
                    <button className="flex w-full text-left">


                      <div className=" mt-6 border-l-2 border-gray-50 pl-10">
                        <p className="text-xl">Feel free open a ticket on our Discord server and one of our team member would love to assist you with your queries.</p>
                      </div>
                    </button>
                  </AccordionItem>
                </div>
              </Accordion>
            </div>
          </div>
        </section>



        <section className="">
          <div className="container mx-auto px-4">

            <div className="flex flex-wrap items-center pt-10 mb-8 lg:mb-0">
              <div className="w-full md:w-1/2 mb-8 md:mb-0">
                <div className="md:flex items-center">
                  <a className="inline-block text-gray-900 text-xl font-bold" href="#hero">
                    <h1 className="text-4xl font-bold">Fridoos</h1>
                  </a>
                  <span className="hidden md:inline-block mx-8 w-px h-8 bg-black"></span>
                  <p className="hidden lg:block text-sm">
                    <span className="font-bold">&copy; 2023 Fridoos.</span>
                    <span className="text-black"> All rights reserved.</span>
                  </p>
                </div>
              </div>

            </div>
            <p className="lg:hidden text-sm">
              <span>&copy; 2023 Fridoos.</span>
              <span className="text-black">All rights reserved.</span>
            </p>
          </div>
        </section>
        <BackToUp>Top</BackToUp>

      </main>
    );
  }
}